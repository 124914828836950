html {
	height: 100%;
}
body {
	height: 100%;
	background-color: #1b1b1b !important;
}
#root {
	height: 100%;
}
.App {
	height: 100%;
}
